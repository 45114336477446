import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

// Static variable to track created instances
let controllerInstances = [];

export default class extends Controller {
    static targets = ["storageSpaceChart", "usersConnectChart", "browserChart", "deviceChart"]

    // Unique ID to identify controller instance
    instanceId = null;

    // Save references to chart instances
    charts = {
        storageSpaceChart: null,
        usersConnectChart: null,
        browserChart: null,
        deviceChart: null
    }

    // Store reference to bound function for later removal
    boundHandleResize = null;

    initialize() {
        // Create unique ID for this instance
        this.instanceId = `chart-controller-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

        // Check and remove old instances before adding new one
        this.cleanupOldInstances();

        // Add this instance to the tracking list
        controllerInstances.push(this.instanceId);
    }

    cleanupOldInstances() {
        // Only keep the current instance in the list
        // Old instances will be removed from the tracking list
        controllerInstances = [];
    }

    // Cleanup controller disconnect
    disconnect() {

        // Remove resize event listener
        if (this.boundHandleResize) {
            window.removeEventListener('resize', this.boundHandleResize);
            this.boundHandleResize = null;
        }

        // Destroy charts instances
        Object.keys(this.charts).forEach(chartKey => {
            const chart = this.charts[chartKey];
            if (chart) {
                chart.destroy();
                this.charts[chartKey] = null;
            }
        });

        // Remove this instance from the tracking list
        const index = controllerInstances.indexOf(this.instanceId);
        if (index > -1) {
            controllerInstances.splice(index, 1);
        }

    }

    connect() {

        // Check if this controller should be the only controller
        if (controllerInstances[0] !== this.instanceId) {
            return;
        }

        try {
            let percentage = parseFloat(this.storageSpaceChartTarget.dataset.percentageStorageSpace || 0);
            let usedDevices = parseFloat(this.deviceChartTarget.dataset.usedDevices || 0);
            let maxDevices = parseFloat(this.deviceChartTarget.dataset.maxDevices || 1);
            let usedBrowsers = parseFloat(this.browserChartTarget.dataset.usedBrowsers || 0);
            let maxBrowsers = parseFloat(this.browserChartTarget.dataset.maxBrowsers || 1);
            let usedLicences = parseFloat(this.usersConnectChartTarget.dataset.usedLicences || 0);
            let maxLicences = parseFloat(this.usersConnectChartTarget.dataset.maxLicences || 1);

            // Draw charts
            this.drawChart("storageSpaceChart", percentage, 'percentage');
            this.drawChart("usersConnectChart", null, 'ratio', usedLicences, maxLicences);
            this.drawChart("browserChart", null, 'ratio', usedBrowsers, maxBrowsers);
            this.drawChart("deviceChart", null, 'ratio', usedDevices, maxDevices);

            // Use a throttle variable to limit the number of resize function calls
            this.resizeTimeout = null;

            // Add resize listener (only once) and store reference
            this.boundHandleResize = this.handleResize.bind(this);
            window.addEventListener('resize', this.boundHandleResize);
        } catch (error) {
            console.error("Error during chart initialization:", error);
        }
    }

    // Handle window resize with throttle to avoid too many calls
    handleResize() {
        // Check if this controller is primary
        if (controllerInstances[0] !== this.instanceId) {
            return;
        }

        // If there's already a timeout waiting to execute, do nothing
        if (this.resizeTimeout) return;

        // Set timeout to limit the number of update calls
        this.resizeTimeout = setTimeout(() => {

            // Redraw all charts with updated height
            Object.keys(this.charts).forEach(chartKey => {
                const chart = this.charts[chartKey];
                if (chart) {
                    chart.updateOptions({
                        chart: {
                            height: window.innerWidth > 1024 ? 220 : 200
                        }
                    });
                }
            });

            // Reset timeout to allow calling again later
            this.resizeTimeout = null;
        }, 150); // Wait 150ms before processing resize event
    }

    drawChart(target, value, type, used = 0, total = 0) {

        // Destroy existing chart if it exists
        if (this.charts[target]) {
            this.charts[target].destroy();
            this.charts[target] = null;
        }

        let percentage;
        let color = '';
        let valueChart = '';
        let emptySpaceColor = '#434343';

        if (type === 'percentage') {
            percentage = value > 0 ? Math.max(1, Math.min(100, Math.floor(value))) : 0;
            valueChart = `${percentage}%`;
        }
        else if (type === 'ratio') {
            percentage = (used / total) * 100;
            percentage = percentage > 100 ? 100 : percentage;
            valueChart = `${used}/${total}`;
        }

        let chartLabel = 'Used';
        // Set the label based on the target
        switch (target) {
            case 'browserChart':
                chartLabel = 'Browser';
                break;
            case 'deviceChart':
                chartLabel = 'Device';
                break;
        }

        if (percentage > 0 && percentage <= 75) {
            color = '#12B76A';
        } else if (percentage >= 76 && percentage <= 90) {
            color = '#F1AB24';
        } else if (percentage >= 91) {
            color = '#D22F26';
        }

        const options = {
            chart: {
                type: 'radialBar',
                height: window.innerWidth > 1024 ? 220 : 200,
                id: `${target}-${this.instanceId}`, // Add unique ID for each chart
            },
            series: [percentage],
            labels: [chartLabel],
            colors: [color],
            plotOptions: {
                radialBar: {
                    track: {
                        background: emptySpaceColor,
                        strokeWidth: '100%',
                    },
                    hollow: {
                        size: '65%',
                    },
                    dataLabels: {
                        name: {
                            fontSize: '14px',
                            offsetY: 25,
                            fontFamily: "Source Sans Pro",
                            color: '#fff',
                            fontWeight: 400,
                            lineHeight: '160%',
                            letterSpacing: '0.014px'
                        },
                        value: {
                            fontSize: '24px',
                            offsetY: -20,
                            fontFamily: "Source Sans Pro",
                            color: '#fff',
                            fontWeight: 700,
                            lineHeight: '160%',
                            letterSpacing: '0.024px',
                            formatter: function () {
                                return valueChart;
                            }
                        }
                    }
                }
            },
            states: {
                normal: {
                    filter: {
                        type: 'none',
                    }
                },
                hover: {
                    filter: {
                        type: 'none',
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                    }
                }
            }
        };

        let chartTarget;
        switch (target) {
            case 'storageSpaceChart':
                chartTarget = this.storageSpaceChartTarget;
                break;
            case 'usersConnectChart':
                chartTarget = this.usersConnectChartTarget;
                break;
            case 'browserChart':
                chartTarget = this.browserChartTarget;
                break;
            case 'deviceChart':
                chartTarget = this.deviceChartTarget;
                break;
            default:
                console.error('Invalid chart target');
                return;
        }


        // Add code to clear old content in target before creating new chart
        if (chartTarget) {
            // Clear old content
            chartTarget.innerHTML = '';
        }

        // Create new chart and store reference
        this.charts[target] = new ApexCharts(chartTarget, options);
        this.charts[target].render();
    }
}