import { Controller } from "@hotwired/stimulus";

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export default class extends Controller {

  static targets = [
    "deviceCount",
    "storageSize",
    "decreaseDevice",
    "decreaseSize",
    "totalPriceDevice",
    "totalPriceStorage",
    "storageQuantity",
    "loginQuantity",
    "proratedPriceDevice",
    "proratedPriceStorage",
    "devicePriceId",
    "storagePriceId",
    "storageLoader",
    "deviceLoader"
  ];

  connect() {
    if (this.hasDecreaseDeviceTarget && this.hasDecreaseSizeTarget) {
      // Disable the decrease button when the controller is first connected
      this.decreaseDeviceTarget.disabled = true;
      this.decreaseSizeTarget.disabled = true;
    }

    const currency =  document.querySelector('.f_currency').value;
    this.currency = currency

    // Initialize the debounced version of fetchProratedPrice
    this.debouncedFetchProratedPrice = debounce(this.fetchProratedPrice.bind(this), 300);
  }

  static values = {
    additionalDevice: String,
    additionalStorage: String,
    minStorage: String
  }

  get additionalDeviceValueNumber() {
    return Number(this.additionalDeviceValue.slice(1));
  }

  get additionalStorageValueNumber() {
    return Number(this.additionalStorageValue.slice(1));
  }

  get minStorageValueNumber() {
    return Number(this.minStorageValue.slice(0, -3));
  }

  // General method to increase or decrease the count
  updateValue(type, change) {
    event.preventDefault();

    if (type === "device") {
      let currentCount = parseInt(this.deviceCountTarget.textContent) || 0;
      let newCount = currentCount + change;

      // Update device count display
      const oneDevice = this.deviceCountTarget.dataset.oneDevice;
      const multipleDevices = this.deviceCountTarget.dataset.multipleDevices;
      this.deviceCountTarget.textContent = newCount > 1 ?
        multipleDevices.replace('%{count}', newCount) :
        oneDevice;

      // Enable/Disable decrease button based on device count
      this.decreaseDeviceTarget.classList.toggle("text-graylight", newCount <= 1);
      this.decreaseDeviceTarget.classList.toggle("bg-bghover", newCount > 1);
      this.updateDeviceQuantity(newCount)
    } else if (type === "storage") {
      let currentSize = parseInt(this.storageSizeTarget.textContent) || 0;
      let newSize = currentSize + change;

      // Update storage size display
      this.storageSizeTarget.textContent = `${newSize} GB`;

      // Enable/Disable decrease button based on storage size
      this.decreaseSizeTarget.classList.toggle("text-graylight", newSize <= this.minStorageValueNumber);
      this.decreaseSizeTarget.classList.toggle("bg-bghover", newSize > this.minStorageValueNumber);
      this.updateStorageQuantity(newSize)
    }
  }

  updateStorageQuantity(newSize) {
    const storageQuantity = Math.floor(newSize / this.minStorageValueNumber); 
    this.storageQuantityTarget.value = storageQuantity;
  }

  updateDeviceQuantity(newCount) {
    if (this.hasLoginQuantityTarget) {
      this.loginQuantityTarget.value = newCount;
    } 
  }

  increaseDevice() {
    this.updateValue("device", 1);
    // Update total price after each change
    this.updateTotalPrice("device");
  }

  decreaseDevice() {
    this.updateValue("device", -1);
    // Update total price after each change
    this.updateTotalPrice("device");
  }

  increaseStorage() {
    this.updateValue("storage", this.minStorageValueNumber);
    this.updateTotalPrice("storage");
  }

  decreaseStorage() {
    this.updateValue("storage", -this.minStorageValueNumber);
    this.updateTotalPrice("storage");
  }

  updateTotalPrice(type) {
    let totalPriceDevice = parseInt(this.totalPriceDeviceTarget?.textContent.slice(1)) || 0;
    let totalPriceStorage = parseInt(this.totalPriceStorageTarget?.textContent.slice(1)) || 0;

    const deviceCount = parseInt(this.deviceCountTarget.textContent) || 0;
    const storageSize = parseInt(this.storageSizeTarget.textContent) || 0;

    // Disable the decrease buttons based on minimum values
    this.decreaseDeviceTarget.disabled = (deviceCount == 1);
    this.decreaseSizeTarget.disabled = (storageSize == this.minStorageValueNumber);

    // Calculate total based on device and storage counts
    switch (type) {
      case "device":
        totalPriceDevice = this.additionalDeviceValueNumber * deviceCount;
        break;
      case "storage":
        totalPriceStorage = this.additionalStorageValueNumber * (storageSize / this.minStorageValueNumber);
        break;
    }

    // Update the total price display if targets are available
    if (this.totalPriceDeviceTarget) {
      this.totalPriceDeviceTarget.textContent = `${this.currency}${totalPriceDevice}`;
    }
    if (this.totalPriceStorageTarget) {
      this.totalPriceStorageTarget.textContent = `${this.currency}${totalPriceStorage}`;
    }
    this.debouncedFetchProratedPrice(type);
  }

  handleAddon(event) {
    const type = event.currentTarget.dataset.addonType;
    this.debouncedFetchProratedPrice(type);
  }
  
  fetchProratedPrice(type) {
    const deviceCount = parseInt(this.deviceCountTarget?.textContent) || 1;
    const storageSize = parseInt(this.storageSizeTarget?.textContent) || 512;
    const storageQuantity = Math.floor(storageSize / this.minStorageValueNumber) || 1;
  
    let priceId;
    let loaderTargets, priceTargets;
  
    // Determine targets based on type
    if (type === "device") {
      priceId = this.devicePriceIdTarget.value;
      loaderTargets = this.deviceLoaderTargets; // Loader for device
      priceTargets = this.proratedPriceDeviceTargets; // Price for device
    } else if (type === "storage") {
      priceId = this.storagePriceIdTarget.value;
      loaderTargets = this.storageLoaderTargets; // Loader for storage
      priceTargets = this.proratedPriceStorageTargets; // Price for storage
    }
    else {
      priceId = document.querySelector(`[data-pricing-calculator-target="${type}PriceId"]`).value;

      loaderTargets = document.querySelectorAll(`[data-pricing-calculator-target="${type}Loader"]`);
      priceTargets = document.querySelectorAll(`[data-pricing-calculator-target="proratedPrice${type}"]`);
    }

    const addonData = {
      price_id: priceId,
      quantity: type === "device" ? deviceCount : (type === "storage" ? storageQuantity : 1)
    };
  
    const params = new URLSearchParams({
      addons: JSON.stringify(addonData),
    });
  
    // Show loader and hide price
    loaderTargets.forEach((loader) => loader.classList.remove("hidden"));
    priceTargets.forEach((price) => price.classList.add("hidden"));
  
    if (priceTargets) {
      fetch(`/settings/generate_addon_invoice?${params}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          this.set_addon_price(loaderTargets, priceTargets, "N/A");
        } else {
          let prorated_amount = `${this.currency}${data.prorated_amount}`;
          this.set_addon_price(loaderTargets, priceTargets, prorated_amount);
        }
      })
      .catch(() => {
        this.set_addon_price(loaderTargets, priceTargets, "N/A");
      });
    }
  }

  set_addon_price(loaderTargets, priceTargets, value) {
    loaderTargets.forEach((loader) => loader.classList.add("hidden"));
    priceTargets.forEach((price) => {
      price.classList.remove("hidden");
      price.textContent = value;
    });
  }

  submitForm(event) {
    const form = event.currentTarget.querySelector("form")

    if(form){
      form.submit();
      form.classList.add("hidden");
      let loader = event.currentTarget.querySelector("#button-loader") || event.currentTarget.querySelector("#button-loader-login");
      
      if(loader){ 
        loader.classList.remove("hidden");
      }
    }
  }

  fetchUpgradeInvoice(event) {
    event.preventDefault();
    const form = event.target.closest("form");
    // price_id for main product, e.g. Starter or Advanced or Premium
    const mainProduct = form.querySelector("input[type='hidden'][name='product_information']")
    const isPremium = event.target.dataset.premium;

    let data = { [mainProduct.name]: { price: mainProduct.value, quantity: 1 } }; // main product amount is always 1
    if (isPremium == "true") {
      // fetch add-ons from DOM
      // get the amount by adding f_{addon name}, e.g. f_device, f_dermoscopy, etc
      let addons = form.querySelectorAll('input[class$="_addons"]');
      let premiumPrice = form.querySelector("#totalPremiumPrice"); // get it from grid subscription
      let nextPrice = form.querySelector("#nextPrice");
      nextPrice.innerHTML = premiumPrice.textContent;

      addons.forEach((addon) => {
        const key = addon.value; // Stripe price ID
        const className = addon.className.split("_")[0]; // becomes for example ["dermoscopy", "module"]
        const amountElement = form.querySelector(`.f_${className}`);
        if (!amountElement.disabled) {
          let addonHash = { [className]: { price: key, quantity: amountElement.value } };
          Object.assign(data, addonHash);
        }
      });
    }

    // do API call
    let url = "/stripe/proration";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content // include CRSF token
      },
      body: JSON.stringify(data)
    })
    .then((response) => { return response.json(); })
    .then((result) => {
      let amountPaid = form.querySelector("#amountPaid");
      let upgradeAmount = form.querySelector("#upgradeAmount");
      let totalUpgrade = form.querySelector("#totalUpgrade");

      amountPaid.innerHTML = `${result.sign_unused}${result.unused_amount}`;
      upgradeAmount.innerHTML = `${result.sign}${result.amount}`;
      totalUpgrade.innerHTML = result.sign == "-" ? `${result.amount[0]}0` : result.amount;

      // replace translation
      let reminderText = form.querySelector("#upgradeReminder");
      reminderText.innerHTML = reminderText.dataset.translation.replace(/upAmount/g, totalUpgrade.innerHTML);
    });
  }
}
