import Datepicker from 'stimulus-datepicker'
import DatepickerUI from '../UI/datepicker_ui' // Import UI rendering methods

export default class extends Datepicker {
  connect () {
    // Call parent's connect method first
    super.connect()

    // Add click handler to input field if it's also a toggle target
    if (this.inputTarget.hasAttribute('data-datepicker-target') &&
      this.inputTarget.dataset.datepickerTarget.includes('toggle')) {
      this.inputTarget.addEventListener('click', this.handleInputClick.bind(this))
    }

    // Add click handler to toggle icon if it exists
    const toggleIcon = this.element.querySelector('.date-picker-toggle')
    if (toggleIcon) {
      toggleIcon.addEventListener('click', this.handleToggleClick.bind(this))
    }
  }

  // Handle input click event
  handleInputClick (event) {
    event.preventDefault()
    event.stopPropagation() // Prevent event from bubbling up

    if (!this.hasCalendarTarget) {
      this.open(true)
    }
  }

  // Handle toggle icon click
  handleToggleClick (event) {
    event.preventDefault()
    event.stopPropagation()

    if (!this.hasCalendarTarget) {
      this.open(true)
    } else {
      this.close(true)
    }
  }

  // Override closeOnOutsideClick to prevent closing when clicking the input or toggle
  closeOnOutsideClick (event) {
    // Don't close if clicking the input field
    if (event.target === this.inputTarget) {
      return
    }

    // Don't close if clicking the toggle icon
    if (event.target.closest('.date-picker-toggle')) {
      return
    }

    // Don't close if clicking inside the calendar
    if (event.target.closest('[data-datepicker-target="calendar"]')) {
      return
    }

    this.close(true)
  }

  // Override the addToggleAction to prevent default toggle behavior
  addToggleAction () {
    // We're handling toggle actions manually
    return
  }

  render (isoDate, animate) {
    const calendarHTML = DatepickerUI.renderCalendar(isoDate, this)
    this.element.insertAdjacentHTML('beforeend', calendarHTML)

    if (animate) {
      this.animateCalendar()
    }
  }

  animateCalendar () {
    this.calendarTarget.classList.add('fade-in')
    if (this.hasCssAnimation(this.calendarTarget)) {
      this.calendarTarget.onanimationend = () => this.calendarTarget.classList.remove('fade-in')
    } else {
      this.calendarTarget.classList.remove('fade-in')
    }
  }

  yearOptions (selected) {
    const years = []
    const thisYear = new Date().getFullYear()
    for (let y = thisYear - 100; y <= thisYear; y++) years.push(y)
    return years
      .map(year => `<option ${year == selected ? 'selected' : ''}>${year}</option>`)
      .join('')
  }
}