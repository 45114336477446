import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["planSelectBox", "roleWarning", "rolePicker", "expiryDate", "premiumSection", "modules", "cost", "reasonField"];

  connect() {
    this.saveBtn = document.querySelector("input[type='submit']");

    if (this.hasRolePickerTarget) {
      var defaultRoleId = document.querySelector("#user_clinic_role").options[0].value; // select first option always
      this.checkRoleEligibility(defaultRoleId);
    }
    this.handleReasonField();

    const dateInput = document.getElementById("subscription_cancel_at_3i");
    const monthInput = document.getElementById("subscription_cancel_at_2i");
    const yearInput = document.getElementById("subscription_cancel_at_1i");
    if (monthInput !== undefined) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      this.adjustMonthOptions(monthInput, tomorrow);
    }
    this.getDaysInMonth(dateInput, yearInput.value, monthInput.value);
  }

  changeRole(event) {
    event.preventDefault();
    var roleOption = event.target.options[event.target.selectedIndex].value; // role id to be checked
    this.checkRoleEligibility(roleOption);
  }

  checkRoleEligibility(roleId) {
    var params = new URLSearchParams(new URL(window.location).search);
    var userId = params.get('owner');
    fetch(`/admin/users/${userId}/check_role?role_id=${roleId}`).then(response => {
      return response.json();
    }).then(data => {
      var saveClinicButton = document.querySelector("#saveClinic");
      if (data.can_manage) {
        this.enableElement(saveClinicButton);
        this.roleWarningTarget.classList.add("hidden");
      } else {
        this.disableElement(saveClinicButton);
        this.roleWarningTarget.classList.remove("hidden");
      }
    }).catch(error => {
      console.error('Error:', error);
      return false;
    });
  }

  validateDate(event) {
    event.preventDefault();

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    
    const dateInput = document.getElementById("subscription_cancel_at_3i");
    const monthInput = document.getElementById("subscription_cancel_at_2i");
    const yearInput = document.getElementById("subscription_cancel_at_1i");
    const selectedDate = new Date(parseInt(yearInput.value), parseInt(monthInput.value) - 1, parseInt(dateInput.value));

    if (selectedDate < tomorrow) {
      // shift to next month if selected date is smaller than tomorrow's date
      monthInput.value = tomorrow.getMonth() + 2 // because month is zero-based
      this.adjustMonthOptions(monthInput, tomorrow);
    } else if (yearInput.value <= tomorrow.getFullYear()) {
      this.adjustMonthOptions(monthInput, tomorrow);
    } else {
      this.showAllMonths(monthInput);
    }

    this.getDaysInMonth(dateInput, yearInput.value, monthInput.value);
  }

  adjustMonthOptions(monthInput, selectedDate) {
    const selectedMonth = selectedDate.getMonth() + 1; // month starts from 0
    const months = monthInput.options;

    for (let i = 0; i < months.length; i++) {
      if (months[i].value < selectedMonth) {
        months[i].classList.add("hidden");
      } else {
        months[i].classList.remove("hidden");
      }
    }
  }

  showAllMonths(monthInput) {
    const months = monthInput.options;
    for (let i = 0; i < monthInput.options.length; i++) {
      months[i].classList.remove("hidden");
    }
  }

  getDaysInMonth(dateInput, year, month) {
    const daysInMonth = new Date(parseInt(year), parseInt(month), 0).getDate();
    if (dateInput.value > daysInMonth) {
      dateInput.value = daysInMonth;
    }

    for (let i = 0; i <= dateInput.options.length - 1; i++) {
      if (dateInput.options[i].value > daysInMonth) {
        dateInput.options[i].classList.add("hidden");
      } else {
        dateInput.options[i].classList.remove("hidden");
      }
    }
  }

  handlePlanChange() {
    if (this.hasPremiumSectionTarget) {
      const featureLevel = this.planSelectBoxTarget.value;
      this.toggleAddonsVisibility(featureLevel);
    }
  }
  
  toggleAddonsVisibility(featureLevel) {
    if (featureLevel == "premium") {
      this.premiumSectionTarget.classList.remove("hidden");
    } else {
      this.premiumSectionTarget.classList.add("hidden");
    }
  }

  handleReasonField() {
    const costValue = this.costTarget.value;
    this.reasonFieldTarget.style.display = (costValue === "Free") ? "block" : "none";
  }

  enableElement(element) {
    element.disabled = false;
    element.classList.remove("field-disabled");
  }

  disableElement(element) {
    element.disabled = true;
    element.classList.add("field-disabled");
  }
}