import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["preview", "input", "cropModal", "cropImage", "overlay", "cropPreview"]

  connect() {
    this.initializeCropSize()
    this.setupGestureEvents();
    this.setupPointerEvents();
  }

  initializeCropSize() {
    this.isDragging = false
    this.isZooming = false
    this.eventState = {}
    this.MINWIDTH = 50
    this.MINHEIGHT = 50
    this.CROPWIDTH = 150
    this.CROPHEIGHT = 150
    this.lastTouchDistance = 0
  }

  setupGestureEvents() {
    const options = { passive: false, capture: false };

    this.cropPreviewTarget.parentElement.addEventListener('wheel', this.handleWheel.bind(this), options);

    this.overlayTarget.addEventListener('touchstart', this.handleTouchStart.bind(this), options);
    this.overlayTarget.addEventListener('touchmove', this.handleTouchMove.bind(this), options);
    this.overlayTarget.addEventListener('touchend', this.handleTouchEnd.bind(this), options);
  }

  handleTouchStart(e) {
    if (e.touches.length === 2) {
      this.isZooming = true;
      this.isDragging = false;
      this.lastTouchDistance = this.getTouchDistance(e.touches);
      e.preventDefault();
    }
  }

  getTouchDistance(touches) {
    const dx = touches[0].clientX - touches[1].clientX;
    const dy = touches[0].clientY - touches[1].clientY;
    return Math.sqrt(dx * dx + dy * dy);
  }

  handleTouchMove(e) {
    if (e.touches.length === 2) {
      e.preventDefault();
      const newDistance = this.getTouchDistance(e.touches);

      const scaleFactor = newDistance / this.lastTouchDistance;

      if (Math.abs(scaleFactor - 1) > 0.01) {
        this.resizeCrop(scaleFactor);
        this.lastTouchDistance = newDistance;
      }
    }
  }

  handleTouchEnd() {
    this.lastTouchDistance = 0;
  }

  handleWheel(e) {
    e.preventDefault();
    const scaleFactor = e.deltaY < 0 ? 1.1 : 0.9;
    this.resizeCrop(scaleFactor);
  }

  resizeCrop(scaleFactor) {
    const newWidth = this.CROPWIDTH * scaleFactor;

    const containerWidth = this.cropPreviewTarget.parentElement.offsetWidth;
    const containerHeight = this.cropPreviewTarget.parentElement.offsetHeight;

    const maxSize = Math.min(containerWidth, containerHeight);

    this.CROPWIDTH = Math.max(this.MINWIDTH, Math.min(newWidth, maxSize));
    this.CROPHEIGHT = this.CROPWIDTH;

    const currentLeft = parseFloat(this.overlayTarget.style.left) || 0;
    const currentTop = parseFloat(this.overlayTarget.style.top) || 0;

    const deltaWidth = this.CROPWIDTH - parseFloat(this.overlayTarget.style.width || this.MINWIDTH);
    const deltaHeight = this.CROPHEIGHT - parseFloat(this.overlayTarget.style.height || this.MINWIDTH);

    let newLeft = currentLeft - deltaWidth / 2;
    let newTop = currentTop - deltaHeight / 2;

    const maxLeft = containerWidth - this.CROPWIDTH;
    const maxTop = containerHeight - this.CROPHEIGHT;

    newLeft = Math.max(0, Math.min(newLeft, maxLeft));
    newTop = Math.max(0, Math.min(newTop, maxTop));

    this.overlayTarget.style.width = `${this.CROPWIDTH}px`;
    this.overlayTarget.style.height = `${this.CROPHEIGHT}px`;
    this.overlayTarget.style.left = `${newLeft}px`;
    this.overlayTarget.style.top = `${newTop}px`;
  }

  onFileSelect(e) {
    const file = e.target.files[0]
    if (!file) return

    const reader = new FileReader()
    reader.onload = (e) => this.handleImageLoad(e.target.result)
    reader.readAsDataURL(file)
  }

  handleImageLoad(src) {
    const img = new Image()
    img.onload = () => {
      const dimensions = this.calculateImageDimensions(img)
      this.setContainerAndImages(dimensions, src)
      this.showCropModal()
      this.initCrop()
    }
    img.src = src
  }

  calculateImageDimensions(img) {
    const maxViewportWidth = Math.min(600, window.innerWidth * 0.9)
    const maxViewportHeight = window.innerHeight * 0.7
    const aspectRatio = img.width / img.height

    let width = img.width
    let height = img.height

    // Scale down if larger than viewport
    if (width > maxViewportWidth) {
      width = maxViewportWidth
      height = width / aspectRatio
    }

    if (height > maxViewportHeight) {
      height = maxViewportHeight
      width = height * aspectRatio
    }

    // Ensure minimum crop size
    const minSize = Math.max(this.CROPWIDTH, this.CROPHEIGHT)
    if (width < minSize) {
      width = minSize
      height = width / aspectRatio
    }
    if (height < minSize) {
      height = minSize
      width = height * aspectRatio
    }

    return { width, height }
  }

  setContainerAndImages(dimensions, src) {
    const container = this.cropPreviewTarget.parentElement
    container.style.width = `${dimensions.width}px`
    container.style.height = `${dimensions.height}px`

    this.cropImageTarget.src = src
    this.cropPreviewTarget.src = src
  }

  initCrop() {
    const container = this.cropPreviewTarget.parentElement
    const containerWidth = container.offsetWidth
    const containerHeight = container.offsetHeight

    const commonStyles = `
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: contain;
        `
    this.cropPreviewTarget.style.cssText = commonStyles
    this.cropImageTarget.style.cssText = commonStyles

    const left = (containerWidth - this.CROPWIDTH) / 2
    const top = (containerHeight - this.CROPHEIGHT) / 2

    this.overlayTarget.style.cssText = `
            position: absolute;
            width: ${this.CROPWIDTH}px;
            height: ${this.CROPHEIGHT}px;
            left: ${left}px;
            top: ${top}px;
            box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            cursor: move;
            z-index: 1;
        `

    this.cropPreviewTarget.classList.add('crop-blur')
    this.cropImageTarget.classList.remove('crop-blur')

  }


  setupPointerEvents() {
    if (this.isZooming) return;
    this.boundStart = this.startMoving.bind(this);
    this.boundMove = this.moving.bind(this);
    this.boundEnd = this.endMoving.bind(this);

    this.overlayTarget.addEventListener('pointerdown', this.boundStart);
    document.addEventListener('pointermove', this.boundMove);
    document.addEventListener('pointerup', this.boundEnd);
    document.addEventListener('pointercancel', this.boundEnd);
  }

  startMoving(e) {
    e.preventDefault()
    this.isDragging = true

    const pageX = e.touches ? e.touches[0].pageX : e.pageX
    const pageY = e.touches ? e.touches[0].pageY : e.pageY

    this.eventState = {
      container_left: this.overlayTarget.offsetLeft,
      container_top: this.overlayTarget.offsetTop,
      mouse_x: pageX,
      mouse_y: pageY
    }
  }

  moving(e) {
    if (!this.isDragging) return
    e.preventDefault()

    const pageX = e.touches ? e.touches[0].pageX : e.pageX
    const pageY = e.touches ? e.touches[0].pageY : e.pageY

    const deltaX = pageX - this.eventState.mouse_x
    const deltaY = pageY - this.eventState.mouse_y

    const newLeft = this.eventState.container_left + deltaX
    const newTop = this.eventState.container_top + deltaY

    const container = this.cropPreviewTarget.parentElement
    const maxLeft = container.offsetWidth - this.CROPWIDTH
    const maxTop = container.offsetHeight - this.CROPHEIGHT

    const left = Math.max(0, Math.min(newLeft, maxLeft))
    const top = Math.max(0, Math.min(newTop, maxTop))

    this.overlayTarget.style.left = `${left}px`
    this.overlayTarget.style.top = `${top}px`
  }

  endMoving() {
    this.isDragging = false
  }

  saveCrop() {
    const canvas = document.createElement('canvas');
    canvas.width = this.CROPWIDTH;
    canvas.height = this.CROPHEIGHT;
    const ctx = canvas.getContext('2d');

    const img = this.cropImageTarget;
    const overlay = this.overlayTarget;
    const container = this.cropPreviewTarget.parentElement;

    const containerRect = container.getBoundingClientRect();
    const overlayRect = overlay.getBoundingClientRect();

    const scaleX = img.naturalWidth / containerRect.width;
    const scaleY = img.naturalHeight / containerRect.height;

    const sourceX = (overlayRect.left - containerRect.left) * scaleX;
    const sourceY = (overlayRect.top - containerRect.top) * scaleY;
    const sourceWidth = overlayRect.width * scaleX;
    const sourceHeight = overlayRect.height * scaleY;

    ctx.drawImage(
      img,
      sourceX,
      sourceY,
      sourceWidth,
      sourceHeight,
      0,
      0,
      this.CROPWIDTH,
      this.CROPHEIGHT
    );

    const dataUrl = canvas.toDataURL('image/png');
    this.previewTarget.src = dataUrl;

    const binaryString = atob(dataUrl.split(',')[1]);
    const array = [];
    for (let i = 0; i < binaryString.length; i++) {
      array.push(binaryString.charCodeAt(i));
    }
    const file = new File([new Uint8Array(array)], 'cropped-avatar.png', {type: 'image/png'});

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    this.inputTarget.files = dataTransfer.files;

    const event = new Event('change', {bubbles: true});
    this.inputTarget.dispatchEvent(event);

    this.hideCropModal();
  }

  showCropModal() {
    this.cropModalTarget.classList.remove('hidden')
  }

  hideCropModal() {
    this.cropModalTarget.classList.add('hidden')

    this.initializeCropSize()
  }

  disconnect() {

    if (this.cropPreviewTarget && this.cropPreviewTarget.parentElement) {
      this.cropPreviewTarget.parentElement.removeEventListener('wheel', this.handleWheel);
    }
    // Cleanup pointer events
    if (this.overlayTarget) {
      this.overlayTarget.removeEventListener('pointerdown', this.boundStart);
      document.removeEventListener('pointermove', this.boundMove);
      document.removeEventListener('pointerup', this.boundEnd);
      document.removeEventListener('pointercancel', this.boundEnd);
    }
  }
}