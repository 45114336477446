/*
 *  FotoFinder Hub V2.0
 *  Copyright (C) 2012 - 2022 FotoFinder Systems GmbH
 *
 *  Last update: 17.08.22.sc
 */

import { Turbo } from '@hotwired/turbo-rails';
import smartupdater from '../jquery/smartupdater2_0_04';
import { setToast } from "../utils";

import select2 from 'select2';
select2();

$(document).on('turbo:load', function() {

  // Patient search
  $("#query:not(.patients)").focus(function(event) {
    $("#search").addClass("active");
  }).keyup(function() {
    var query = $(this).val();
    var search_suggest_results = $("#search_suggest_results");
    var search_clear_btn = $("#search_clear_btn");
    if (query.length > 0) {
      search_clear_btn.show();
    } else {
      search_clear_btn.hide();
    }

    if (query.length > 1) {
      $.ajax({
        url: $(this).parents('form').data('search_suggest-path') + '.js?query=' + query,
        complete: function(data) {
          if (data.responseText.split("<li").length - 1 != 0 || data.responseText.split("<tr").length - 1 != 0) {
            search_suggest_results.show();
            search_suggest_results.html(data.responseText);
          } else {
            search_suggest_results.hide();
          }
        }
      });
    } else {
      if(search_suggest_results.prop("tagName") != "TBODY") {
        search_suggest_results.hide();
      } else {
        const form = $(this).parents('form');
        const per_page = form.find("#per_page")[0];
        const page = form.find("#page")[0];
        const direction = form.find("#direction")[0];
        const sort = form.find("#sort")[0];
        let search = [];
  
        if(per_page) search.push(`per_page=${per_page.value}`)
        if(page) search.push(`page=${page.value}`)
        if(direction) search.push(`direction=${direction.value}`)
        if(sort) search.push(`sort=${sort.value}`)
        search = search.join("&");
        $.ajax({
          url: $(this).parents('form').data('search_suggest-path') + '.js?' + search,
          complete: function(data) {
            if (data.responseText.split("<li").length - 1 != 0 || data.responseText.split("<tr").length - 1 != 0) {
              search_suggest_results.show();
              search_suggest_results.html(data.responseText);
            } else {
              search_suggest_results.hide();
            }
          }
        });
      }
    }
  });
  // Patient search clear query
  $("button#search_clear_btn").click(function() {
    query = $("#query");
    query.textContent = "";
    query.focus();
    var search_suggest_results = $("#search_suggest_results");
    if(search_suggest_results.prop("tagName") == "TBODY") {
      const form = $(this).parents('form');
      const per_page = form.find("#per_page")[0];
      const page = form.find("#page")[0];
      const direction = form.find("#direction")[0];
      const sort = form.find("#sort")[0];
      let search = [];

      if(per_page) search.push(`per_page=${per_page.value}`)
      if(page) search.push(`page=${page.value}`)
      if(direction) search.push(`direction=${direction.value}`)
      if(sort) search.push(`sort=${sort.value}`)
      search = search.join("&");
      $.ajax({
        url: $(this).parents('form').data('search_suggest-path') + '.js?' + search,
        complete: function(data) {
          if (data.responseText.split("<li").length - 1 != 0 || data.responseText.split("<tr").length - 1 != 0) {
            search_suggest_results.show();
            search_suggest_results.html(data.responseText);
          } else {
            search_suggest_results.hide();
          }
        }
      });
    } else {
      search_suggest_results.hide();
    }
    $(this).hide();
  });
  // Patient search: Clicking the body hides the search form; it won't when clicking inside the form itself.
  $("#search").click(function(event) { event.stopPropagation(); });
  $("body").click(function() { $("#search").removeClass("active"); });

  // second opinion (expert+clinic admin)
  $("#expert_second_opinion").smartupdater({dataType: 'script', minTimeout: 5000, maxTimeout: 60000, multiplier: 2, url : $('#expert_second_opinion').attr('data-periodical_updates_url')}, function(data) {
    $("tr[data-link]").each(function() {
      $(this).addClass('link').click(function() {
        Turbo.visit($(this).data("link"));
      });
    });
  });

  $("#user_role").on("change", function() {
    $.ajax({
      url: "filter_group_by_role",
      type: "GET",
      data: { role: $(this).val() }
    })
    return true;
  });

  // Toggle for consultation form.
  $("section.consultation h3").click(function() { $(this).parent().toggleClass('active') });

  // Clicking the body hides the search form; it won't when clicking inside the form itself.
  $("#search").click(function(event) { event.stopPropagation(); });
  $("body").click(function() { $("#search").removeClass("active") });

  // Clear placeholders upon submit.
  $("form").submit(function() {
    $("input[placeholder],textarea[placeholder]").each(function() {
      if ($(this).val() == $(this).attr("placeholder")) { $(this).val(""); }
    });
  });

  //
  $(".second_opinion_image :input").each(function() {
    if ($(this).attr("data-type") == "comment") {
      $(this).keyup(function() {
        validate_second_opinion_image_form($(this).parents("section"));
      });
    } else {
      $(this).change(function() {
        validate_second_opinion_image_form($(this).parents("section"));
      })
    }
    validate_expert_second_opinion_images();
  });
  
  window.check_validation_of_second_opinion_forms = function() {
    if ($("section.second_opinion_image").length == $("section.valid").length) {
      $(".save_button").each(function() {
        $(this).removeAttr("disabled");
        $(this).removeClass("field-disabled");
      });
    } else {
      $(".save_button").each(function() {
        $(this).attr("disabled", true);
        $(this).addClass("field-disabled");
      });
    }
  };

  window.add_valid_to_form = function(image_id) {
    var form = $("#"+image_id);
    form.addClass("valid");
    form.removeClass("invalid")
  };
  window.remove_valid_from_form = function(image_id) {
    var form = $("#"+image_id);
    form.removeClass("valid");
    form.addClass("invalid");
  };

  $(".image_compare").click(function() {
    var image_ids = [];
    $(".image_select:checked").each(function() {
      image_ids.push($(this).attr("value"));
    });
    if (image_ids.length > 2) {
      alert("Please select a maximum of 2 images");
    } else if (image_ids.length == 0) {
      alert("Please select at least one photo");
    } else {
      window.images = window["images"];
      window.show_full_screens(image_ids, images);
    }
    return false;
  });

  // admin, expert, and clinic admin panel's side navbar
  $("#get-nav-bar").click(function(event) {
    event.preventDefault();
    $("#nav-bar").css("width", "16rem");
    $("#close-side-navbar").removeClass("hidden");
  });
  $("#close-side-navbar").click(function(event) {
    event.preventDefault();
    $("#nav-bar").css("width", "0");
    $("#close-side-navbar").addClass("hidden");
  });

  // show/hide side navbar in tablet mode
  $("#show-side-navbar-btn").click(function(event) {
    event.preventDefault();
    $("#nav-bar").addClass("side-navbar-open");
    $("#hide-side-navbar-btn").removeClass("sm:hidden");
    $("#hide-side-navbar-btn").addClass("sm:block");
    if ($("#nav-settings").hasClass("settings-open") && $("#settings-dropdown").css('display') === 'none') {
      $("#settings-label").trigger("click");
    }
  });

  $("#hide-side-navbar-btn").click(function(event) {
    event.preventDefault();
    $("#nav-bar").removeClass("side-navbar-open");
    $("#hide-side-navbar-btn").removeClass("sm:block");
    $("#hide-side-navbar-btn").addClass("sm:hidden");
    if($("#settings-dropdown").css('display') == 'block') {
      $("#settings-label").trigger("click");
    }
  });

   // show/hide user popup
   $("#nav-show-user-popup").click(function(event) {
    event.preventDefault();
    event.stopPropagation();
    $("#nav-user-popup").toggleClass("hidden");
  })

  // hide popups
  const POPUPS = ["nav-user-popup"]
  $(window).click(function(event) {
    $("#nav-user-popup").addClass("hidden");
    if(!$("#nav-language-dropup").hasClass("hidden")) {
        $("#nav-language").trigger("click");
    }
    $("#switch-account-popup").addClass("hidden");
  })

  $("#nav-user-popup").click(function(event) {
    event.stopPropagation();
  })

  // Make table rows entirely linkable based on URL in 'data-link'.
  $("tr[data-link] input").click(function(e) { e.stopPropagation(); });
  $('tr[data-link]').each(function() {
    return $(this).addClass('link').click(function() {
      return Turbo.visit($(this).data('link'));
    });
  });

  $("tr[data-link] td.preview").click(function(e) { e.stopPropagation(); });

  function checkModules(event) {
    let saveBtn = $("input[type='submit'][form='new_subscription']");
    if ($(this).val().length === 0) {
      const alertMessage = $("#module-alert").data("message");
      if (alertMessage) setToast(alertMessage, "warning");

      if (saveBtn) saveBtn.prop("disabled", true).addClass("field-disabled");
    } else {
      if (saveBtn) saveBtn.prop("disabled", false).removeClass("field-disabled");
    }
  }

  $(".user-licenses").select2();
  $(".user-role").select2();
  $(".addon-modules").select2().on("select2:unselect select2:select", function (event) { checkModules.call(this, event) });
  $(".assessments-select2").select2();
  $(".consultations-select").select2({
    minimumResultsForSearch: -1
  });

  $(".assessments-select2").on("change", function() {
    var patientID = $("#patient-id").val();
    var consultationID = $("#consultation-id").val();
    var imageID = $("#image-id").val();
    var diagnoseAssessmentIds = $("#diagnose-assessment-ids").val().length == 0 ? [""] : $("#diagnose-assessment-ids").val();
    var studyAssessmentIds = $("#study-assessment-ids").val().length == 0 ? [""] : $("#study-assessment-ids").val();
    var assessmentIds = diagnoseAssessmentIds.concat(studyAssessmentIds);
    var msgContainer = $("#assessments-messages-container");
    $.ajax({
      url: "/patients/"+patientID+"/consultations/"+consultationID+"/consultation_images/"+imageID+"/update_assessments",
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        toggleLoadingAndInfoIcon();
      },
      type: "PUT",
      data: {
        "image": { "assessment_ids": assessmentIds }
      },
      success: function(data) {
        msgContainer.removeClass("hidden");
        msgContainer.find("div").addClass("green-flash");
        msgContainer.find("div").removeClass("red-flash");
        msgContainer.find("div").find("span").text(data.message);
        setTimeout(function() {
          toggleLoadingAndInfoIcon();
        }, 300);
      },
      error: function(xhr, errorMsg, errorStatus) {
        var errorMsg = xhr.responseText;
        msgContainer.removeClass("hidden");
        msgContainer.find("div").addClass("red-flash");
        msgContainer.find("div").removeClass("green-flash");
        msgContainer.find("div").find("span").text(errorStatus >= 500 ? "Internal Server Error" : errorMsg);
        toggleLoadingAndInfoIcon();
      }
    });
  });
  $("#image_comment").on("blur", function() {
    var patientID = $("#patient-id").val();
    var consultationID = $("#consultation-id").val();
    var imageID = $("#image-id").val();
    var comment = $("#image_comment").val();
    var msgContainer = $("#assessments-messages-container");
    $.ajax({
      url: "/patients/"+patientID+"/consultations/"+consultationID+"/consultation_images/"+imageID+"/update_comment",
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        toggleLoadingAndInfoIcon();
      },
      type: "PUT",
      data: {
        "image": { "comment": comment }
      },
      success: function(data) {
        msgContainer.removeClass("hidden");
        msgContainer.find("div").addClass("green-flash");
        msgContainer.find("div").removeClass("red-flash");
        msgContainer.find("div").find("span").text(data.message);
        setTimeout(function() {
          toggleLoadingAndInfoIcon();
        }, 300);
      },
      error: function(xhr, errorMsg, errorStatus) {
        var errorMsg = xhr.responseText;
        msgContainer.removeClass("hidden");
        msgContainer.find("div").addClass("red-flash");
        msgContainer.find("div").removeClass("green-flash");
        msgContainer.find("div").find("span").text(errorStatus >= 500 ? "Internal Server Error" : errorMsg);
        toggleLoadingAndInfoIcon();
      }
    });
  });

  $("#upload-diagnose").select2({
    dropdownParent: '#upload-photos-form',
    placeholder: 'Select a diagnose',
    minimumResultsForSearch: Infinity
  });
  $("#upload-study").select2({
    dropdownParent: '#upload-photos-form',
    placeholder: 'Select a study',
    minimumResultsForSearch: Infinity
  });
  $("#upload-treatment").select2({
    dropdownParent: '#upload-photos-form',
    placeholder: 'Select a treatment',
    minimumResultsForSearch: Infinity
  });
  $("#upload-localization").select2({
    dropdownParent: '#upload-photos-form',
    placeholder: 'Select a localization',
    minimumResultsForSearch: Infinity
  });
  $("#consultation_id").select2({
    dropdownParent: '#upload-photos-form',
    minimumResultsForSearch: Infinity
  });
  $('#upload-diagnose, #upload-study, #upload-treatment').on('select2:opening select2:closing', function( event ) {
    var $searchfield = $(this).parent().find('.select2-search__field');
    $searchfield.prop('disabled', true);
  });
  $('#upload-diagnose, #upload-study, #upload-treatment').on('select2:select', function( event ) {
    var $searchfield = $(this).parent().find('.select2-search__field');
    $searchfield.css("display", "none");
  });
  $('#upload-diagnose, #upload-study, #upload-treatment').on('select2:unselect', function( event ) {
    if($(this).find(':selected').length == 0) {
      var $searchfield = $(this).parent().find('.select2-search__field');
      $searchfield.css("display", "block");
    }
  });
  var newOption = new Option('Unknown', '-1', true, true);
  $('#upload-localization').prepend(newOption).trigger('change');

  function toggleLoadingAndInfoIcon() {
    $("#load-spinner").toggleClass("hidden");
    $("#info-icon").toggleClass("hidden");
  }

  var credit_query = $("#credit_query").val();
  if (credit_query != undefined && credit_query != "") {
    location.hash = "#search-spending-history";
  }

  if ($("#first-login").val()) {
    var user_id = $("#user-id").val();
    if (document.cookie.match('first_login_'+user_id) == null) {
      $("#first-login-modal").modal({
        fadeDuration: 300,
        showClose: false
      });
    }
    $("#first-login-modal").on($.modal.CLOSE, (event, modal) => {
      // set cookie when modal begings closing
      var expiry_date = new Date();
      expiry_date.setTime(expiry_date.getTime() + (43200 * 1000)); // expires in 12 hours
      document.cookie = "first_login_" + user_id + "=true;expires=" + expiry_date.toUTCString() + ";SameSite=Lax;";
    });
    $(".click_plan").click((event) => {
      $.modal.close();
    });
  }
  token = $("meta[name='csrf-token']").attr("content"); 
  $("input[name='authenticity_token']").val(token);
});

function validate_expert_second_opinion_images() {
  $("section.second_opinion_image").each(function() {
    validate_second_opinion_image_form($(this));
  });
}

function validate_second_opinion_image_form(section) {
  var form_values = {};
  section.find(":input:not(input[type=hidden]):not(input[type=radio])").each(function() {
    form_values[$(this).attr("name")] = $(this).val();
  });
  section.find(":input[type=radio]:checked").each(function() {
    form_values[$(this).attr("name")] = $(this).val();
  });
  var section_id = section.attr('id');
  $.post("/expert/second_opinion_images/" + section_id +"/validate", form_values);
}


//
// Scroll to a specific position (Id)
//
$(document).on('turbo:load', function(){
  // Select all links with hashes
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $('html, body, section, div').animate({
            scrollTop: target.offset().top
          }, 500, function() {
            // Callback after animation
            // Must change focus!
            var $target = $(target);
            // $target.focus();
            if ($target.is(":focus")) { // Checking if the target was focused
              return false;
            } else {
              $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
              // $target.focus(); // Set focus again
            }
          });
        }
      }
    });
});


