import { Controller } from '@hotwired/stimulus'
import { setToast } from '../utils'

export default class extends Controller {
  static targets = ['form', 'preview', 'removeImageInput', 'consent']

  connect () {
    if (this.hasFormTarget) {
      // Store initial form state when component mounts
      this.initialState = this.getCurrentFormState();
      this.disableSubmit()

      // Validate and bind events for all form fields
      this.formTarget.querySelectorAll('input, select').forEach(input => {
        if (this.isRequiredField(input)) {
          this.validateInput(input)
        }

        // Add event listeners to all fields to detect any changes
        input.addEventListener('input', () => {
          if (this.isRequiredField(input)) {
            this.validateInput(input)
          }
          this.validateFormChanges()
        })

        input.addEventListener('change', () => {
          if (this.isRequiredField(input)) {
            this.validateInput(input)
          }
          this.validateFormChanges()
        })
      })
    }
    super.connect()
  }

  // Check if a field is required
  isRequiredField (input) {
    return input.classList.contains('required')
  }

  // Validate single input and update visual feedback
  validateInput (input) {
    if (input.value) {
      this.validInput(input)
    } else {
      this.invalidInput(input)
    }
  }

  // Get current state of all form fields
  getCurrentFormState () {
    const formData = {}
    this.formTarget.querySelectorAll('input, select').forEach(input => {
      formData[input.name] = input.value
    })
    return formData
  }

  // Check for any changes in form and validate required fields
  validateFormChanges () {
    const currentState = this.getCurrentFormState()
    // Compare current state with initial state
    const hasChanges = Object.keys(currentState).some(key =>
      currentState[key] !== this.initialState[key]
    )

    // Enable submit only if form has changes and all required fields are valid
    if (hasChanges && this.isFormValid()) {
      this.enableSubmit()
    } else {
      this.disableSubmit()
    }
  }

  // Check if all required fields have values
  isFormValid () {
    return ![...this.getRequiredFields()].some(input => !input.value)
  }

  enableSubmit () {
    this.formTarget.querySelector('input[type=submit]').classList.remove('field-disabled', 'pointer-events-none')
  }

  disableSubmit () {
    this.formTarget.querySelector('input[type=submit]').classList.add('field-disabled', 'pointer-events-none')
  }

  getRequiredFields () {
    return this.formTarget.querySelectorAll('input.required, select.required')
  }

  selectFiles (e) {
    const file = e.target.files[0]
    if (file) {
      const imageName = e.currentTarget.dataset.field
      const reader = new FileReader()
      reader.onload = () => {
        this.getPreviews(imageName).forEach(img => img.src = reader.result)
        this.getRemoveImageInputs(imageName).forEach(input => input.value = false)
      }
      reader.readAsDataURL(file)
    }
  }

  removeImage (e) {
    const imageName = e.currentTarget.dataset.field
    this.getRemoveImageInputs(imageName).forEach(input => input.value = true)
    this.getPreviews(imageName).forEach(img => img.src = '/assets/base/image-unavailable.jpg')
  }

  getRemoveImageInputs (field) {
    return this.removeImageInputTargets.filter(input => input.dataset.field == field)
  }

  getPreviews (field) {
    return this.previewTargets.filter(img => img.dataset.previewFor == field)
  }

  reset () {
    this.previewTargets.forEach(img => img.src = this.previewTarget.dataset.original)
    if (this.hasFormTarget) {
      this.formTarget.reset()
      this.validateForm()
    }
  }

  validateDateOfBirth (e) {
    const target = e.currentTarget
    if (target.value == '') {
      target.value = target.defaultValue
    } else {
      const difInMonths = (Date.now() - Date.parse(target.value)) / (1000 * 60 * 60 * 24 * 30)
      if (difInMonths <= 1) {
        target.value = target.defaultValue
      }
    }
  }

  validateForm () {
    let passed = true
    this.getRequiredFields().forEach(input => {
      if (input.value == '') {
        passed = false
        this.invalidInput(input)
      } else {
        this.validInput(input)
      }
    })
    if (passed) {
      this.enableSubmit()
    } else {
      this.disableSubmit()
    }
  }

  validInput (input) {
    input.classList.remove('expert-not-done')
    input.classList.add('expert-done')
  }

  invalidInput (input) {
    input.classList.remove('expert-done')
    input.classList.add('expert-not-done')
  }

  signConsent (e) {
    const target = e.currentTarget
    if (target.value == 'true') {
      this.consentTarget.value = parseInt(Date.now() / 1000)
      setToast(target.dataset.warning, 'warning')
    } else {
      this.consentTarget.value = ''
    }
  }
}