import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "loader"]

  connect() {
    this.loaderTarget.classList.add("hidden")
  }

  submitForm(event) {
    event.preventDefault() 
    this.formTarget.classList.add("hidden")
    this.loaderTarget.classList.remove("hidden")
    this.formTarget.submit()
  }
}