import { Controller } from "@hotwired/stimulus";

const CSS_CLASSES = {
  ACTIVE: {
    CONTAINER: 'bg-ffred',
    CHECKBOX: ['border-white', 'text-ffredl']
  },
  INACTIVE: {
    CONTAINER: 'bg-ebony-clay-alt',
    MESSAGE: 'text-lightbluegray'
  },
  BUTTON: {
    DISABLED: 'text-graylight',
    ENABLED: 'bg-ebony-clay-alt'
  }
};

const PREFIX_PATH = '/assets/svg/settings/';
const BROWSER_MULTIPLIER = 2;

/**
 * Grid Subscription Controller
 * Manages subscription pricing, device counts, and storage options
 */
export default class extends Controller {
  static targets = [
    "gridShow", "deviceCount", "storageSize", "decreaseDevice", "icon",
    "decreaseSize", "totalPrice", "totalPriceTooltip", "button", "exclusiveDerPrice", "grid",
    "restriction"
  ];

  connect() {
    this._initializeState();
    this.currentStoragePrice();

    if (this._is_restricted()) this._deactivate();
  }

  change(event) {
    const { target: checkbox } = event;
    const container = this._getContainer(checkbox);
    const message = this._getMessage(container);
    const checkedModules = this._getCheckedModules();

    // Prevent unchecking the last module
    if (checkedModules.length === 0 && !checkbox.checked) {
      checkbox.checked = true;
      return;
    }
    // display device
    this._handleDeviceCountCheckboxChange(checkbox);
    this._updateModuleSelection(checkbox, checkedModules);
    this._disable_module(checkedModules);
    this._updateModuleStyles(checkbox, container, message);
    this.updateTotalPrice(checkbox.checked ? "choseCheckBox" : "unCheckBox");
  }

  toggleProposer() {
    const elements = {
      lastRow: document.getElementById("lastRow"),
      lastRowFirst: document.getElementById("lastRowFirst"),
      lastRowFirstTablet: document.getElementById("lastRowFirstTablet"),
      lastRowTablet: document.getElementById("lastRowTablet"),
      seeMore: document.getElementById("seeMore"),
      seeLess: document.getElementById("seeLess")
    };

    this._toggleGridVisibility(elements);
  }

  toggleMobile(event) {
    const container = event.currentTarget.closest('[data-product]');
    const grid = container.querySelector('.grid-cols-2');
    const seeMore = container.querySelector('[data-see-more]');

    grid.classList.toggle('hidden');
    seeMore.classList.toggle('hidden');
  }

  currentStoragePrice() {
    let newStorageSize = parseInt(this.storageSizeTarget?.textContent.slice(1)) || 0;
    let totalPrice = this.basePremium;
    if (newStorageSize > this.storage) {
      let additionalStorage = newStorageSize - this.storage;
      totalPrice += (additionalStorage / this.storage) * this.additionalUnit;
  
      this.totalPriceTargets.forEach(target => {
        target.textContent = `${this.currency}${totalPrice}`;
      });
      this.totalPriceTooltipTargets.forEach(target => {
        target.textContent = `${this.currency}${totalPrice}`;
      });
      this._updateStorageDisplay(newStorageSize);
      this._updateStorageFields(newStorageSize);
      this._updateStorageButton(newStorageSize);
      this._updateStorageTooltip(newStorageSize);
    } else {
      this.totalPriceTargets.forEach(target => {
        target.textContent = `${this.currency}${totalPrice}`;
      });
      this.totalPriceTooltipTargets.forEach(target => {
        target.textContent = `${this.currency}${totalPrice}`;
      });
    }
  }

  updateValue(type, change) {
    if (type === "device") {
      this._handleDeviceUpdate(change);
    } else if (type === "storage") {
      this._handleStorageUpdate(change);
    }

    this.updateTotalPrice(change > 0 ? "increase" : "decrease");
  }

  // Handles device updates
  _handleDeviceUpdate(change) {
    const newCount = this._calculateNewDeviceCount(change);
    const minDevice = this._getCheckedModules().length ;

    this._updateDeviceDisplay(newCount);
    this._updateDeviceFields(change);
    this._updateDeviceButton(newCount, minDevice);
    this._updateDeviceTooltip(newCount, minDevice);
  }

  _calculateNewDeviceCount(change) {
    const currentCount = this._getCurrentDeviceCount();
    return currentCount + change;
  }

  _getCurrentDeviceCount() {
    return parseInt(this.deviceCountTarget.textContent) || 0;
  }

  _updateDeviceDisplay(newCount) {
    this.deviceCountTargets.forEach(target => {
      const oneDevice = target.dataset.oneDevice;
      const multipleDevices = target.dataset.multipleDevices;
      target.textContent = newCount > 1 ?
        multipleDevices.replace('%{count}', newCount) :
        oneDevice;
    });
    this._updateBrowserLoginText(newCount)
  }

  _updateDeviceFields(change) {
    const deviceFields = document.querySelectorAll('[class*="f_device"]');
    const increment = change < 0 ? -1 : 1;

    deviceFields.forEach(field => {
      const newValue = (parseInt(field.value) || 0) + increment;
      field.value = newValue;
      field.disabled = newValue === 0;
    });
  }

  _updateDeviceButton(newCount, minDevice) {
    const isCountValid = newCount > minDevice;
    this.decreaseDeviceTargets.forEach(target => {
      target.classList.toggle("text-graylight", !isCountValid);
      target.classList.toggle("bg-ebony-clay-alt", isCountValid);
    });
  }

  _updateDeviceTooltip(newCount, minDevice) {
    const isCountValid = newCount > minDevice;
    if (isCountValid) {
      this._updateTooltip("device_tooltips", `${this.currency}${(newCount-minDevice) * this.additionalUnit}`);
    } else {
      this._hideTooltip("device_tooltips");
    }
  }

  // Handles storage updates
  _handleStorageUpdate(change) {
    const currentSize = this._getCurrentStorageSize();
    const currentStorage = parseInt(this.decreaseSizeTarget.dataset.userCurrentStorage);
    const newSize = currentSize + change;

    this._updateStorageDisplay(newSize);
    this._updateStorageFields(change);
    this._updateStorageButton(newSize);
    this._updateStorageTooltip(newSize);

    if (currentStorage === newSize) {
      this.decreaseSizeTarget.classList.remove('bg-ebony-clay-alt')
      this.decreaseSizeTarget.classList.add('text-graylight')
    } else {
      this.decreaseSizeTarget.classList.add('bg-ebony-clay-alt')
      this.decreaseSizeTarget.classList.remove('text-graylight')
    }
  }

  _getCurrentStorageSize() {
    return parseInt(this.storageSizeTarget.textContent) || 0;
  }

  _updateStorageDisplay(newSize) {
    this.storageSizeTargets.forEach(target => {
      target.textContent =`${newSize} GB`
    });
  }

  _updateStorageFields(change) {
    const storageFields = document.querySelectorAll('[class*="f_storage"]');
    const increment = change < 0 ? -1 : 1;

    storageFields.forEach(field => {
      const newValue = (parseInt(field.value) || 0) + increment;
      field.value = newValue;
      field.disabled = newValue === 0;
    });
  }

  _updateStorageButton(newSize) {
    const currentStorage = parseInt(this.decreaseSizeTarget.dataset.userCurrentStorage);
    const isStorageSizeValid = newSize > currentStorage;
    this.decreaseSizeTargets.forEach(target => {
      target.classList.toggle("text-graylight", !isStorageSizeValid);
      target.classList.toggle("bg-ebony-clay-alt", isStorageSizeValid);
    });
  }

  _updateStorageTooltip(newSize) {
    const isStorageSizeValid = newSize > this.storage;
    if (isStorageSizeValid) {
      this._updateTooltip("storage_tooltips", `${this.currency}${(newSize/this.storage -1)*this.additionalUnit}`
      );
    } else {
      this._hideTooltip("storage_tooltips");
    }
  }

  // Convenience methods for value updates
  increaseDevice(event) {
    event.preventDefault();
    this.updateValue("device", 1);
  }

  decreaseDevice(event) {
    event.preventDefault();
    this.updateValue("device", -1);
  }

  increaseStorage(event) {
    event.preventDefault();
    this.updateValue("storage", this.storage);
  }

  decreaseStorage(event) {
    event.preventDefault();
    this.updateValue("storage", -this.storage);
  }

  updateTotalPrice(type) {
    let totalPrice = parseInt(this.totalPriceTarget?.textContent.slice(1)) || 0;

    const deviceCount = parseInt(this.deviceCountTarget.textContent) || 0;
    const storageSize = parseInt(this.storageSizeTarget.textContent) || 0;

    const currentStorage = parseInt(this.decreaseSizeTarget.dataset.userCurrentStorage);
    const checkedModules = this._getCheckedModules().length
    // Disable the decrease buttons based on minimum values
    this.decreaseDeviceTargets.forEach(target => {
      target.disabled = (deviceCount <= checkedModules);
    });

    this.decreaseSizeTargets.forEach(target => {
      target.disabled = (storageSize == currentStorage);
    });

    // Calculate total based on action type
    switch (type) {
      case "increase":
        totalPrice += this.additionalUnit;
        break;
      case "decrease":
        totalPrice -= this.additionalUnit;
        break;
      case "choseCheckBox":
        totalPrice += this.additionalModule;
        break;
      case "unCheckBox":
        totalPrice -= this.additionalModule;
        break;
    }

    // Update price displays
    this.totalPriceTargets.forEach(target => {
      target.textContent = `${this.currency}${totalPrice}`;
    });
    this.totalPriceTooltipTargets.forEach(target => {
      target.textContent = `${this.currency}${totalPrice}`;
    });

    return totalPrice;
  }

  // Private helper methods
  _initializeState() {
    this.decreaseDeviceTargets.forEach(target => target.disabled = true);
    this.decreaseSizeTargets.forEach(target => target.disabled = true);
    ['f_aesthetics', 'f_trichoscopy'].forEach(className => {
      document.querySelectorAll(`[class*="${className}"]`).forEach(selection => selection.disabled = true);
    });
    const additionalModule = document.querySelector('.f_aesthetics_price').value
    const modulePrice = document.querySelector('.f_storage_price').value
    const basePrice =  document.querySelector('.f_premium').value;
    const currency =  document.querySelector('.f_currency').value;
    const storageValue = document.querySelector('.min_storage').value;
    this.additionalModule = parseFloat(additionalModule)
    this.additionalUnit = parseFloat(modulePrice)
    this.basePremium = parseFloat(basePrice) + parseFloat(additionalModule)
    this.currency = currency
    this.storage = parseInt(storageValue.replace(/[^0-9]/g, ''))
  }

  _getContainer(checkbox) {
    return checkbox.closest('[data-grid-subscription-target="container"]');
  }

  _getMessage(container) {
    return container.querySelector('[data-grid-subscription-target="message"]');
  }

  _getCheckedModules() {
    return Array.from(this.element.querySelectorAll('input[type="checkbox"]'))
      .filter(cb => cb.checked)
      .map(cb => cb.id);
  }

   _updateModuleSelection(checkbox, checkedModules) {
    const moduleFields = document.querySelectorAll(`[class*="f_${checkbox.id}"]`);
    const derAesElement = document.getElementById("der_aes");
    moduleFields.forEach(field => {
      field.disabled = !checkbox.checked;
    });

    if (checkedModules.length > 1) {
      this._updateTooltip("additional_module_tooltips",
        this._createModuleTooltip(checkedModules.length));

      if (derAesElement) {
        derAesElement.classList.remove("hidden");
      }
    } else {
      this._hideTooltip("additional_module_tooltips");
      if (derAesElement) {
        derAesElement.classList.add("hidden");
      };
    }

    if (checkedModules.length > 0) {
      this._updateTooltip("package_tooltips",
        this._createPackageTooltip(checkedModules[0]));
    }
  }

  _updateModuleStyles(checkbox, container, message) {
    const { checked } = checkbox;
    container.classList.toggle(CSS_CLASSES.ACTIVE.CONTAINER, checked);
    container.classList.toggle(CSS_CLASSES.INACTIVE.CONTAINER, !checked);

    CSS_CLASSES.ACTIVE.CHECKBOX.forEach(cls =>
      checkbox.classList.toggle(cls, checked));

    message.classList.toggle(CSS_CLASSES.INACTIVE.MESSAGE, !checked);
  }

  _toggleGridVisibility(elements) {
    this.gridShowTargets.forEach(target => target.classList.toggle("hidden"));
    const isVisible = !this.gridShowTargets[0].classList.contains("hidden");
    this._updateGridStyles(elements, isVisible);
  }

  _updateGridStyles(elements, isVisible) {
    const { lastRow, lastRowFirst, lastRowFirstTablet, lastRowTablet,
      seeMore, seeLess } = elements;

    if (!isVisible) {
      if (lastRow) {
        lastRow.classList.add("rounded-br-lg", "!border-b-bgred");
      }
      if (lastRowFirst) {
        lastRowFirst.classList.add("rounded-bl-lg");
      }
      if (seeMore) seeMore.classList.remove("hidden");
      if (seeLess) seeLess.classList.add("hidden");
      if (lastRowFirstTablet) {
        lastRowFirstTablet.classList.add("sm:rounded-bl-lg");
      }
      if (lastRowTablet) {
        lastRowTablet.classList.add("sm:!border-b", "sm:!border-b-bgred", "sm:rounded-br-lg");
      }
    } else {
      if (lastRow) {
        lastRow.classList.remove("rounded-br-lg", "!border-b-bgred");
      }
      if (lastRowFirst) {
        lastRowFirst.classList.remove("rounded-bl-lg");
      }
      if (seeMore) seeMore.classList.add("hidden");
      if (seeLess) seeLess.classList.remove("hidden");
      if (lastRowFirstTablet) {
        lastRowFirstTablet.classList.remove("sm:rounded-bl-lg");
      }
      if (lastRowTablet) {
        lastRowTablet.classList.remove("sm:!border-b", "sm:!border-b-bgred", "sm:rounded-br-lg");
      }
    }
  }

  _updateTooltip(id, content) {
    const element = document.getElementById(id);
    const container = element.closest('[container-tooltip="parent-div"]');
    if (element) {
      element.textContent = content.trim();
      container.classList.remove("hidden");
    }
  }

  _hideTooltip(id) {
    const element = document.getElementById(id);
    const container = element.closest('[container-tooltip="parent-div"]');
    if (container) {
      container.classList.add("hidden");
    }
  }

  _createModuleTooltip(moduleCount) {
    return `${this.currency}${(moduleCount-1) * this.additionalModule}`;
  }

  _createPackageTooltip(packageName) {
    return `
       ${packageName}
    `;
  }

  _disable_module(checkedModules){
    if (checkedModules.length == 1 && checkedModules[0] === "dermoscopy") {
      if (!this._is_restricted()) this._activate();
    } else {
      this._deactivate();
    }
  }

  _activate() {
    this.iconTargets.forEach(icon => {
      const basePath = icon.dataset.basePath
      icon.src = `${PREFIX_PATH}${basePath}.svg`
    })
    this.gridTarget.classList.remove("text-grid-dim")
    this.gridShowTargets.forEach(grid => grid.classList.remove("text-grid-dim"))
    this.buttonTargets.forEach(button => {
      button.classList.remove("bg-ebony-clay-alt", "pointer-events-none")
      button.classList.add("bg-ffred")
    })
    this.exclusiveDerPriceTargets.forEach(price => {
      price.textContent = price.dataset.originalPrice
    })
  }

  _deactivate() {
    this.iconTargets.forEach(icon => {
      const basePath = icon.dataset.basePath
      icon.src = `${PREFIX_PATH}${basePath}_gray.svg`
    })
    this.gridTarget.classList.add("text-grid-dim")
    this.gridShowTargets.forEach(grid => grid.classList.add("text-grid-dim"))
    this.buttonTargets.forEach(button => {
      button.classList.add("bg-ebony-clay-alt", "pointer-events-none")
      button.classList.remove("bg-ffred")
    })
    this.exclusiveDerPriceTargets.forEach(price => {
      price.textContent = "-"
    })
  }

  _handleDeviceCountCheckboxChange(checkbox) {
    const delta = checkbox.checked ? 1 : -1;
    const newCount = this._calculateNewDeviceCount(delta);
    this._updateDeviceDisplay(newCount);
  }

  _updateBrowserLoginText(count) {
    const countElements = document.querySelectorAll('[data-browser-login]');
    const browserCount = count * BROWSER_MULTIPLIER;

    countElements.forEach(element => {
      element.textContent = browserCount;
    });
  }

  _is_restricted() {
    // Starter and Advanced are blocked if country is USA or Canada
    if (this.hasRestrictionTarget) {
      const blocked = this.restrictionTarget.value;
      return (blocked === "true" ? true : false);
    } else {
      return true;
    }
  }

}